import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import NestedContentRow from "../components/nestedContentRow";
import ALTile from "../components/ALTile";
import ALForm from "../components/ALForm";
import smoothScrollTo from "../components/smoothScroll";

const ContactUsPage = _ => {
    const imgData = useStaticQuery(graphql`
        query {
            contactusHeader: file(relativePath: { eq: "ContactUsHeaderImg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            phoneIcon: file(relativePath: { eq: "PhoneIcon.png" }) {
                childImageSharp {
                    fixed(width: 60) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            clockIcon: file(relativePath: { eq: "ClockIcon.png" }) {
                childImageSharp {
                    fixed(width: 60) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            locationIcon: file(relativePath: { eq: "LocationIcon.png" }) {
                childImageSharp {
                    fixed(width: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            headsetIcon: file(relativePath: { eq: "HeadsetIcon.png" }) {
                childImageSharp {
                    fixed(width: 150) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    const [headerImgVisible, isHeaderVisible] = useState(false);
    const toggleHeaderImage = _ => {
        if (window.innerWidth <= 1366) {
            return isHeaderVisible(false);
        }
        isHeaderVisible(true);
    };

    useEffect(_ => {
        toggleHeaderImage();
        window.addEventListener("resize", toggleHeaderImage);
        return function() {
            window.removeEventListener("resize", toggleHeaderImage);
        }
    });

    return (
        <Layout pageID={"contactus"}>
            <SEO
                title="Contact Us"
                description={"Interested in learning more about ALMobile? Need to contact ALMobile Support? Contact us here for everything from product information and online product assistance, to getting a free quote today!"}
            />
            <div className="contentRow pageHeader">
                <div className="subContentRow">
                    <NestedContentRow
                        iconData={
                            (headerImgVisible) ?
                                {
                                    iconType: "fluid",
                                    icon: imgData.contactusHeader.childImageSharp.fluid,
                                    iconAlt: "Contact Us Header"
                                }
                                :
                                {}
                        }
                        textData={{
                            level1: "CONTACT US",
                            level2: "Let's Have a Conversation",
                            level3: <div className={"level3"}>
                                <div
                                    className={"link btn"}
                                    onClick={ _ => {
                                        const scrollTop = window.document.getElementById("contactFormRow");
                                        if (scrollTop && scrollTop.offsetTop) {
                                            smoothScrollTo(scrollTop.offsetTop, 300);
                                        }
                                    }}
                                >
                                    Talk With Us
                                </div>
                            </div>
                        }}
                    />
                </div>
            </div>
            <div className={"contentRow row1"}>
                <div className={"subContentRow"}>
                    <div className={"header"}>Contact ALMobile Team</div>
                    <div className={"supportData"}>
                        <ALTile
                            className={"supportDesc"}
                            mainText={"Looking for Support?"}
                            subText={
                                `If you are an existing ALMobile client looking for support or professional 
                                services, please visit the ALMobile Customer Portal - your resource for all features of ALMobile.`
                            }
                            useWebinar={false}
                        />
                        <div className={"ALInfo"}>
                            <NestedContentRow
                                iconData={{
                                    icon: imgData.phoneIcon.childImageSharp.fixed,
                                    iconType: "fixed",
                                    iconAlt: "Phone Icon"
                                }}
                                link={
                                    <a
                                        role="button"
                                        className={"link number"}
                                        href="tel:8552725555"
                                    >
                                        855.272.5555
                                    </a>
                                }
                            />
                            <NestedContentRow
                                iconData={{
                                    icon: imgData.clockIcon.childImageSharp.fixed,
                                    iconType: "fixed",
                                    iconAlt: "Clock Icon",
                                }}
                                textData={{
                                    level1: "8:00AM - 5:00 PM CST",
                                    level2: "Monday - Friday"
                                }}
                            />
                            <NestedContentRow
                                iconData={{
                                    icon: imgData.locationIcon.childImageSharp.fixed,
                                    iconType: "fixed",
                                    iconAlt: "Location Icon",
                                }}
                                link={
                                    <a
                                        className={"link street"}
                                        target={"_blank"}
                                        rel={"noreferrer noopener"}
                                        href="https://maps.google.com/?q=421 E Hickory St #104 Denton, TX 76201"
                                    >
                                        421 E Hickory St #104 Denton, TX 76201
                                    </a>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={"contentRow row2"}>
                <div className={"subContentRow"}>
                    <div className={"header"}>Get Phenomenal Support</div>
                    <ALTile
                        iconType={"fixed"}
                        icon={imgData.headsetIcon.childImageSharp.fixed}
                        iconAlt={"Headset Icon"}
                        mainText={"ALMobile Support"}
                        useWebinar={false}
                    />
                </div>
            </div>
            <ALForm/>
        </Layout>
    )
};

export default ContactUsPage;