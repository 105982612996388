import React from "react";
import NumberFormat from "react-number-format";

const ALPhone = ({formReg, disabled, inputLabel}) => {
    return (
        <div className={"ALInputContainer"}>
            <div className={"ALInputLabel"}>{(inputLabel) && inputLabel}</div>
            <div className={`ALInput`}>
                {
                    (inputLabel) &&
                    <NumberFormat
                        type={"tel"}
                        name={"Phone"}
                        disabled={disabled}
                        format={"(###) ###-####"}
                        allowEmptyFormatting
                        getInputRef={formReg}
                    />
                }
            </div>
        </div>
    )
};

export default ALPhone;