import React, { useEffect, useState } from "react";
import {Link} from "gatsby";
import { useForm } from "react-hook-form";

import ALInput from "./formInputs/ALInput";
import ALSelect from "./formInputs/ALSelect";
import ALTextarea from "./formInputs/ALTextarea";
import ALButton from "./formInputs/ALButton";
import ALPhone from "./formInputs/ALPhone";
import ALGoogleRecaptcha from "./formInputs/ALGoogleRecaptcha";

const ALForm = _ => {
    const {handleSubmit, triggerValidation, register, setValue, formState: {isSubmitting}, errors, clearError, reset} = useForm();
    const [submitted, formSubmitted] = useState(false);
    const [isValid, validateForm] = useState(false);
    const [response, setFormResponse] = useState({});
    const [disallowSubmit, submitState] = useState(true);

    const formSubmit = async _ => {
        const valid = await triggerValidation([
            "First Name",
            "Last Name",
            "Email",
            "Phone",
            "Company",
            "Job Role"
        ]);
        if(!valid) {
            Object.keys(errors).map(elem => {
                errors[elem].ref.classList.add("error");
                errors[elem].ref.placeholder = "Required Field";
            });
        }
        validateForm(valid);
    };
    const onSubmit = async data => {
        if(isValid) {
            try {
                const sendData = {
                    formType: "contactus",
                    formFields: {...data}
                };
                const formResponse = await fetch(process.env.GATSBY_API_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(sendData),
                });
                const formResp = await formResponse.json();
                console.log(formResp);
                setFormResponse(formResp);
                formSubmitted(true);
                reset();
            } catch (error) {
                setFormResponse({result: false, message: "Sorry, we could not process your request at this time."});
                formSubmitted(true);
            }
        }
    };

    const captchaReady = _ => submitState(false);

    useEffect( _ => {
        ALGoogleRecaptcha({
            placeHolder: "contactFormRow",
            formReg: register,
            captchaReady: captchaReady,
            regSet: setValue,
            actionType: "ALContact"
        });
    }, []);

    return (
        <div id={"contactFormRow"} className={"contentRow"}>
            <div className={"subContentRow"}>
                <div className={"header"}>Get In Touch With Us</div>
                <div className={"subHeader"}>Please complete our form below and an Austin Lane Representative will respond to you promptly.</div>
                {
                    (!submitted)
                        ? <form
                            autoComplete={"off"}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <ALInput
                                disabled={isSubmitting}
                                formReg={register({
                                    required: true
                                })}
                                inputLabel={"First Name*"}
                                inputName={"First Name"}
                                inputPlaceHolder={""}
                            />

                            <ALInput
                                disabled={isSubmitting}
                                formReg={register({
                                    required: true
                                })}
                                inputLabel={"Last Name*"}
                                inputName={"Last Name"}
                                inputPlaceHolder={""}
                            />
                            <ALInput
                                disabled={isSubmitting}
                                formReg={register({
                                    required: true
                                })}
                                inputLabel={"Email*"}
                                inputName={"Email"}
                                inputType={"email"}
                                inputPlaceHolder={""}
                            />
                            <ALPhone
                                inputLabel={"Phone*"}
                                formReg={register({
                                    required: true
                                })}
                            />
                            <ALInput
                                disabled={isSubmitting}
                                formReg={register({
                                    required: true
                                })}
                                inputLabel={"Company*"}
                                inputName={"Company"}
                                inputPlaceHolder={""}
                            />
                            <ALInput
                                disabled={isSubmitting}
                                formReg={register({
                                    required: true
                                })}
                                inputLabel={"Job Role*"}
                                inputName={"Job Role"}
                                inputPlaceHolder={""}
                            />
                            <ALInput
                                disabled={isSubmitting}
                                formReg={register}
                                inputLabel={"State/Province"}
                                inputName={"State Province"}
                                inputPlaceHolder={""}
                                clearError={clearError}
                            />
                            <ALInput
                                disabled={isSubmitting}
                                formReg={register}
                                inputLabel={"Country"}
                                inputName={"Country"}
                                inputPlaceHolder={""}
                                clearError={clearError}
                            />
                            <ALSelect
                                disabled={isSubmitting}
                                formReg={register}
                                options={[
                                    {
                                        label: "Sales",
                                        val: "sales"
                                    },
                                    {
                                        label: "Training",
                                        val: "training"
                                    },
                                    {
                                        label: "Support",
                                        val: "support"
                                    }
                                ]}
                            />
                            <ALTextarea
                                disabled={isSubmitting}
                                formReg={register}
                                inputLabel={"Comments"}
                                inputName={"Comments"}
                                inputPlaceHolder={""}
                            />
                            <ALButton
                                handleSubmit={formSubmit}
                                disabled={disallowSubmit}
                                formReg={register}
                            />
                        </form>
                        :
                        <div
                            className={`formResponse ${(response.result) ? "success" : "alert"}`}
                        >
                            {(response.result) ? "Thank you, your response has been recorded." : response.message}
                            <div
                                className={"formResponseOptions"}
                            >
                                <Link
                                    className={"link"}
                                    to={"/"}
                                >
                                    Return Home
                                </Link>
                                <div
                                    className={"btn"}
                                    onClick={ _ => formSubmitted(false)}
                                >
                                    Resubmit
                                </div>
                            </div>
                        </div>
                }
            </div>
            <div id={"g-captcha"} style={{textAlign: "center", fontSize: "14px"}}>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>
        </div>
    )
};

export default ALForm;